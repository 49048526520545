import React from "react";
import { Input } from "antd";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

export default function NumberInput({
  control,
  validation = {},
  defaultValue = 0,
  inputName,
  inputNumberProps,
  errors,
  int = false,
  type = "number",
  label = "",
  className = "number-input",
  stringMode = false,
  showNumpad = true,
}) {
  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={defaultValue}
      rules={validation}
      render={({ onChange: originalOnchange, value, name }) => {
        const onChange = (v) => {
          if (int) {
            originalOnchange(parseInt(v));
          } else {
            originalOnchange(v);
          }
        };
        return (
          <>
            <Input
              {...inputNumberProps}
              name={name}
              value={value}
              onChange={(e) => {
                const inputNumber = e.target.value;
                if (
                  inputNumber !== "" &&
                  !inputNumber[inputNumber.length - 1].match(/\d{1}/) &&
                  !inputNumber[inputNumber.length - 1].match(/-/)
                ) {
                  console.log("");
                } else {
                  if (inputNumber[inputNumber.length - 1] === "-") {
                    console.log("");
                  } else onChange(inputNumber);
                }
              }}
              className={className}
              type={type}
            />
            {label ? <span style={{ marginLeft: 5 }}>{label}</span> : null}

            <small className="invalid-feedback">
              {errors && errors.message}
            </small>
          </>
        );
      }}
    />
  );
}

NumberInput.propTypes = {
  control: PropTypes.any,
  validation: PropTypes.object,
  inputNumberProps: PropTypes.object,
  defaultValue: PropTypes.any,
  type: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.any,
  inputName: PropTypes.string,
  className: PropTypes.string,
  stringMode: PropTypes.bool,
  int: PropTypes.bool,
  showNumpad: PropTypes.bool,
};
