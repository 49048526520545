import React from "react";
import PropTypes from "prop-types";

function Label({ required = true, label, isMultiline = false, tooltip }) {
  return (
    <div className="input-label">
      {required ? <span className="input-label-tag">必須</span> : null}
      <span
        className={`input-label-text ${
          isMultiline ? "input-label-text__multiline" : ""
        }`}
      >
        {label}
      </span>
      {tooltip && tooltip()}
    </div>
  );
}

Label.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  isMultiline: PropTypes.bool,
  tooltip: PropTypes.any,
};

export default Label;
