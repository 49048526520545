import React from "react";
import PropTypes from "prop-types";
import { STROKE } from "constant";

export default function HelpCircleIcon({ width, height, stroke = STROKE }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5ZM0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9Z"
        fill={stroke}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.24074 5.6224C8.82177 5.55054 8.39088 5.62927 8.0244 5.84465C7.65792 6.06004 7.37949 6.39817 7.23843 6.79917C7.12847 7.11177 6.78592 7.27603 6.47332 7.16607C6.16073 7.0561 5.99647 6.71355 6.10643 6.40096C6.34153 5.73263 6.80558 5.16907 7.41638 4.81009C8.02719 4.45112 8.74532 4.3199 9.4436 4.43967C10.1419 4.55945 10.7752 4.92248 11.2315 5.46449C11.6877 6.00642 11.9374 6.69231 11.9364 7.40069C11.9361 8.51886 11.1054 9.27522 10.4692 9.6993C10.1329 9.92355 9.80076 10.0892 9.55511 10.1984C9.43143 10.2533 9.32754 10.2949 9.25315 10.3233C9.21591 10.3374 9.18592 10.3484 9.16439 10.356L9.13853 10.3651L9.13061 10.3678L9.12795 10.3687C9.12795 10.3687 9.44131 10.2642 9.12694 10.369C8.81258 10.4738 8.47201 10.3042 8.36722 9.9898C8.26253 9.67574 8.432 9.33629 8.74579 9.23116L8.7481 9.23037L8.76238 9.22536C8.776 9.22052 8.79757 9.21269 8.82596 9.20187C8.88282 9.18021 8.96642 9.14681 9.06775 9.10178C9.2721 9.01096 9.53999 8.87658 9.80361 8.70084C10.3674 8.325 10.7364 7.88155 10.7364 7.40007C10.7371 6.97498 10.5872 6.56249 10.3135 6.23729C10.0397 5.91209 9.6597 5.69426 9.24074 5.6224Z"
        fill={stroke}
      />
      <path
        d="M9.00117 13.7997C9.443 13.7997 9.80117 13.4415 9.80117 12.9997C9.80117 12.5579 9.443 12.1997 9.00117 12.1997C8.55934 12.1997 8.20117 12.5579 8.20117 12.9997C8.20117 13.4415 8.55934 13.7997 9.00117 13.7997Z"
        fill={stroke}
      />
    </svg>
  );
}

HelpCircleIcon.defaultProps = {
  width: "18",
  height: "18",
};

HelpCircleIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};
