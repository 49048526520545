import React from "react";
import PropTypes from "prop-types";
import { STROKE } from "constant";

export default function LineLogo({ width, height, stroke = STROKE }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5333 1H4.46667C2.54667 1 1 2.54667 1 4.46667V13.5333C1 15.4533 2.54667 17 4.46667 17H13.5333C15.4533 17 17 15.4533 17 13.5333V4.46667C17 2.54667 15.4533 1 13.5333 1ZM13.5467 11.2C12.3333 12.6133 9.6 14.32 8.97333 14.5867C8.34667 14.8533 8.44 14.4133 8.46667 14.2667C8.48 14.1733 8.54667 13.7733 8.54667 13.7733C8.56 13.6267 8.58667 13.4 8.53333 13.24C8.46667 13.08 8.21333 13 8.02667 12.9467C5.22667 12.5733 3.16 10.6133 3.16 8.28C3.16 5.66667 5.77333 3.54667 8.98667 3.54667C12.2 3.54667 14.8133 5.66667 14.8133 8.28C14.8 9.33333 14.4 10.28 13.5467 11.2Z"
        fill={stroke}
      />
      <path
        d="M7.78724 7.04004H7.37391C7.30724 7.04004 7.25391 7.09337 7.25391 7.14671V9.68004C7.25391 9.74671 7.30724 9.78671 7.37391 9.78671H7.78724C7.85391 9.78671 7.90724 9.73337 7.90724 9.68004V7.14671C7.90724 7.09337 7.85391 7.04004 7.78724 7.04004Z"
        fill={stroke}
      />
      <path
        d="M10.599 7.04004H10.199C10.1324 7.04004 10.079 7.09337 10.079 7.14671V8.65337L8.91904 7.08004C8.91904 7.08004 8.91904 7.06671 8.9057 7.06671C8.9057 7.06671 8.9057 7.06671 8.89237 7.05337C8.89237 7.05337 8.89237 7.05337 8.87904 7.05337H8.4657C8.39904 7.05337 8.3457 7.10671 8.3457 7.16004V9.69337C8.3457 9.76004 8.39904 9.80004 8.4657 9.80004H8.87904C8.9457 9.80004 8.99904 9.74671 8.99904 9.69337V8.18671L10.159 9.76004C10.1724 9.77337 10.1724 9.78671 10.1857 9.78671C10.1857 9.78671 10.1857 9.78671 10.199 9.78671C10.199 9.78671 10.199 9.78671 10.2124 9.78671C10.2257 9.78671 10.2257 9.78671 10.239 9.78671H10.599C10.6657 9.78671 10.719 9.73337 10.719 9.68004V7.14671C10.719 7.09337 10.6657 7.04004 10.599 7.04004Z"
        fill={stroke}
      />
      <path
        d="M6.80102 9.16019H5.69435V7.14686C5.69435 7.08019 5.64102 7.02686 5.57435 7.02686H5.16102C5.09435 7.02686 5.04102 7.08019 5.04102 7.14686V9.68019C5.04102 9.70685 5.05435 9.73352 5.06768 9.76019C5.09435 9.77352 5.12102 9.78685 5.14768 9.78685H6.77435C6.84102 9.78685 6.88102 9.73352 6.88102 9.66685V9.25352C6.92102 9.21352 6.86768 9.16019 6.80102 9.16019Z"
        fill={stroke}
      />
      <path
        d="M12.8525 7.66652C12.9191 7.66652 12.9591 7.61318 12.9591 7.54652V7.13318C12.9591 7.06652 12.9058 7.01318 12.8525 7.01318H11.2258C11.1991 7.01318 11.1725 7.02652 11.1458 7.03985C11.1325 7.06652 11.1191 7.09318 11.1191 7.11985V9.65318C11.1191 9.67985 11.1325 9.70652 11.1458 9.73318C11.1725 9.74652 11.1991 9.75985 11.2258 9.75985H12.8525C12.9191 9.75985 12.9591 9.70652 12.9591 9.63985V9.22652C12.9591 9.15985 12.9058 9.10652 12.8525 9.10652H11.7458V8.73318H12.8525C12.9191 8.73318 12.9591 8.67985 12.9591 8.61318V8.19985C12.9591 8.13318 12.9058 8.07985 12.8525 8.07985H11.7458V7.66652H12.8525Z"
        fill={stroke}
      />
    </svg>
  );
}

LineLogo.defaultProps = {
  width: "18",
  height: "18",
};

LineLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};
