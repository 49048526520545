import React from "react";
import PropTypes from "prop-types";
import { STROKE } from "constant";

export default function GourmetIcon({ width, height, stroke = STROKE }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0748 0C10.0863 0 8.47461 1.98963 8.47461 4.23524C8.47461 5.60194 9.1173 6.80713 9.90283 7.5575C10.5922 8.21668 11.0157 8.72427 11.1004 9.82564V17.0876C11.1004 17.5913 11.5091 18 12.0128 18H12.1364C12.6401 18 13.0488 17.5913 13.0488 17.0876V9.82561C13.1334 8.72423 13.5569 8.21665 14.2463 7.55747C15.0318 6.80709 15.6745 5.6019 15.6745 4.2352C15.6745 1.98963 14.0622 0 12.0748 0Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.62997 0.864445V4.70966C5.62997 4.95519 5.43113 5.1546 5.18556 5.1546H5.06634C4.82134 5.1546 4.62193 4.95519 4.62193 4.70966V0.846867C4.62193 0.213492 4.19566 0.0234375 3.78641 0.0234375C3.37772 0.0234375 2.95145 0.213492 2.95145 0.846867V4.70966C2.95145 4.95519 2.75204 5.1546 2.50651 5.1546H2.38786C2.14233 5.1546 1.94345 4.95519 1.94345 4.70966V0.864445C1.94345 -0.269906 0.326262 -0.231445 0.326262 0.877066C0.326262 2.20421 0.326262 4.54871 0.326262 4.54871C0.318563 6.58778 0.805828 7.11677 1.61003 7.75233C2.25824 8.26431 2.81248 8.55653 2.81248 9.82764V17.0891C2.81248 17.5928 3.22061 18.0015 3.72489 18.0015H3.8485C4.35222 18.0015 4.76091 17.5928 4.76091 17.0891V9.82764C4.76091 8.55653 5.31462 8.26427 5.96283 7.75233C6.76759 7.11677 7.25482 6.58778 7.24712 4.54871C7.24712 4.54871 7.24712 2.20421 7.24712 0.877066C7.24716 -0.231445 5.62997 -0.269906 5.62997 0.864445Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

GourmetIcon.defaultProps = {
  width: "18",
  height: "18",
};

GourmetIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};
