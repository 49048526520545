import React, { useEffect } from "react";
import { useHistory } from "react-router";
import bg from "assets/bg.svg";

import "containers/NetReservation/style/index.less";
import { Typography, Divider } from "antd";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { RESERVATION_CONDITIONS } from "../../constant";

export default function NetReservationConfirm(props) {
  const history = useHistory();
  const isMobile = useMediaQuery({
    query: "(max-width: 770px)",
  });
  const store = useSelector((state) => state.netReservationReducer.store);
  const isImmediateReservation =
    store?.netReservation?.reservationCondition ===
    RESERVATION_CONDITIONS[1].value;

  useEffect(() => {
    const state = history.location.state;
    if (!state?.store) {
      history.goBack();
    }
  }, [history]);
  const { Title, Text } = Typography;
  const handleClose = () => {
    window.close();
  };
  const openLineMeAddFriend = () => {
    const state = history.location.state;
    window.open(state.store.netReservation.lineMeUrl);
  };
  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        padding: "0",
      }}
      className={"container"}
    >
      <div className="net-form">
        <Title style={{ padding: "0 25px" }}>
          {" "}
          {history.location.state?.store?.name} ネット予約フォーム
        </Title>
        <Divider />
        <div style={{ marginLeft: "30px" }}>
          <Text
            style={{
              fontSize: 22,
              fontWeight: 600,
              display: "block",
              margin: "15px 0",
            }}
          >
            {!isImmediateReservation
              ? "仮予約を承りました。"
              : "ご予約を承りました。"}
          </Text>
          <div
            dangerouslySetInnerHTML={{
              __html:
                history.location.state?.store?.netReservation.netReservationThanksPageNote.replace(
                  /\n/g,
                  "<br />"
                ),
            }}
            style={{ fontSize: "14px" }}
          />

          <div style={{ margin: "15px 0" }}>
            <button
              hidden={isMobile}
              type="button"
              className="button button-default button-default-2"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={handleClose}
            >
              画面を閉じる
            </button>

            {/* <button
              type="button"
              className="button button-primary button-primary-2"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() => history.goBack()}
            >
              続けて予約をとる
            </button> */}

            <button
              type="button"
              hidden={!history.location.state.store.netReservation.lineMeUrl}
              className="button button-primary button-primary-2"
              style={{ marginTop: 15, backgroundColor: "#00B900" }}
              onClick={openLineMeAddFriend}
            >
              LINE友達追加
            </button>
          </div>
          <Text style={{ fontWeight: 600 }} hidden={!isMobile}>
            このページを閉じる場合は、ブラウザの×ボタンを押してください。
          </Text>
        </div>
      </div>
    </div>
  );
}
