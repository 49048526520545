import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography, Button } from "antd";
import {
  Select,
  NumberInput,
  List,
  Input,
  PhoneInput,
  TextArea,
  CheckboxSingle,
  FormCalendar,
} from "components/FormControllers";
import Label from "components/Form/atoms/Label";
import SectionHeader from "components/Form/atoms/SectionHeader";
import NetReservationTableDetailsModal from "../Modal/NetReservationTableDetailsModal";
import { RESERVATION_CONDITIONS } from "../../constant";
import { useWatch } from "react-hook-form";
import dayjs from "dayjs";
import _ from "lodash";
import NetReservationMenuDetailModal from "../Modal/NetReservationMenuDetailModal";
import { convertPrice } from "../../utils/common";
import scrolldown from "assets/Icons/scrolldown.svg";
import { useSelector } from "react-redux";

function NetReservationForm({
  control,
  openHours,
  menus,
  errors,
  setEndTime,
  getAvailableTable,
  availableTables,
  availableTablesOnline = [],
  store,
  publicHolidays,
  multipleMenuSelect,
  emailRequired,
  menuRequired,
  fullnameRequired,
  dispCampaign,
  dispChildNum,
}) {
  const isIphone = useSelector((state) => state.layoutReducer.isIphone);
  const { Text, Title } = Typography;
  const [tableOnlineDetail, setTableOnlineDetail] = useState({
    showDialog: false,
    data: {},
  });

  const [menuDetail, setMenuDetail] = useState({
    showDialog: false,
    data: {},
  });

  const isImmediateReservation =
    store?.netReservation?.reservationCondition ===
    RESERVATION_CONDITIONS[1].value;
  const watcher = useWatch({
    control,
  });
  const { weeklyHolidays, hasHolidays } = store;
  const isWeeklyHoliday = (day) => {
    return _.find(weeklyHolidays, (h) => dayjs(day).day() === h.id);
  };
  const isPublicHoliday = (day) => {
    if (!hasHolidays) return false;
    return _.find(publicHolidays, (pH) => {
      const isSameDay = dayjs(day).date() === parseInt(pH.day);
      const isSameMonth = dayjs(day).month() === parseInt(pH.month) - 1; // dayjs month is zero indexed
      return isSameDay && isSameMonth;
    });
  };
  let displayTableOnlineOptions = [];
  if (watcher.date && availableTablesOnline.length > 0 && watcher.startTime) {
    if (
      watcher.date.hour() !== 0 &&
      watcher.date.minute() !== 0 &&
      watcher.date.second() !== 0 &&
      watcher.date.millisecond() !== 0
    ) {
      watcher.date = dayjs(watcher.date)
        .date(watcher.date.date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
    }
    let date = null;
    if (!isPublicHoliday(watcher.date) && !isWeeklyHoliday(watcher.date)) {
      date = dayjs(watcher.date);
    }
    const tableOnDates = _.find(availableTablesOnline, (a) => {
      if (!date) {
        return false;
      }
      return (
        dayjs(a.day).tz("Asia/Tokyo").format("YYYY-MM-DD") ===
        date.format("YYYY-MM-DD")
      );
    });
    if (tableOnDates?.tables) {
      displayTableOnlineOptions = tableOnDates.tables;
      let totalSeat = Number(watcher.adultNumber);
      if (dispChildNum) {
        totalSeat = totalSeat + Number(watcher.childNumber);
      }
      displayTableOnlineOptions = displayTableOnlineOptions.filter(
        (table) =>
          table.numberOfSeats >= totalSeat && !!table.onlineBookingTableName
      );
    }
  }

  const handleCancel = () => {
    setTableOnlineDetail((prevState) => {
      return { ...prevState, showDialog: false, item: {} };
    });
  };

  const handleShowDetailTableOnline = (item = {}) => {
    setTableOnlineDetail((prevState) => {
      return { ...prevState, showDialog: true, data: item };
    });
  };

  const handleShowDetailMenu = (item = {}) => {
    setMenuDetail((prevState) => {
      return { ...prevState, showDialog: true, data: item };
    });
  };

  const handleMenuCancel = () => {
    setMenuDetail((prevState) => {
      return { ...prevState, showDialog: false, data: {} };
    });
  };

  return (
    <div
      className="form-wrapper res-form-wrapper"
      style={{ marginTop: 60, padding: "0 25px" }}
    >
      <Row className="form-section">
        <Col span={24}>
          <SectionHeader label={"空席検索"} />
        </Col>
        <Col span={24}>
          <div className="input-group">
            <Label label={"ご来店時間をお選びください。"} required={false} />
            <div className="input-elements-inline">
              <div className="input-element input-element-visit-time">
                <Select
                  control={control}
                  inputName={"startTime"}
                  placeholder={"-"}
                  Options={openHours.map((t) =>
                    t !== "--" ? { key: t, label: t } : { key: null, label: t }
                  )}
                  callback={setEndTime}
                />
              </div>

              <div
                className="input-element input-element-visit-time"
                style={{ marginLeft: 15, visibility: "hidden" }}
              >
                <Select
                  control={control}
                  inputName={"endTime"}
                  placeholder={"-"}
                  Options={openHours.map((t) => ({ key: t, label: t }))}
                  callback={getAvailableTable}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col span={24} style={{ marginTop: 25 }}>
          <div className="input-group">
            <Label label={"ご来店人数を入力してください。"} required={false} />
            <div className="input-elements-inline">
              <div className="input-element" style={{ width: 100 }}>
                {dispChildNum ? (
                  <Label label={"大人"} required={false} />
                ) : (
                  <Label label={""} required={false} />
                )}
                <NumberInput
                  control={control}
                  inputName="adultNumber"
                  defaultValue={1}
                  validation={{
                    required: "人数を入力してください。",
                  }}
                  inputNumberProps={{
                    min: 1,
                    max: 999,
                    placeholder: "999",
                    type: "number",
                  }}
                  label={"名"}
                />
              </div>
              {dispChildNum && (
                <div className="input-element" style={{ marginLeft: 20 }}>
                  <Label label={"子ども"} required={false} />
                  <NumberInput
                    control={control}
                    inputName="childNumber"
                    inputNumberProps={{
                      defaultValue: 1,
                      min: 0,
                      max: 999,
                      placeholder: "999",
                      type: "number",
                    }}
                    label={"名"}
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className="input-group">
            <Label
              label={"ご来店日の日付をお選びください。"}
              required={false}
            />
            <div className="input-element" style={{ marginRight: 25 }}>
              <FormCalendar
                control={control}
                inputName={"date"}
                showOverlay={
                  !isImmediateReservation
                    ? !availableTables.length
                    : !availableTablesOnline.length
                }
                availableTables={
                  !isImmediateReservation
                    ? availableTables
                    : availableTablesOnline
                }
                store={store}
                publicHolidays={publicHolidays}
                callback={getAvailableTable}
              />
              <div className="calender-label">
                ● 空席あり ▲ 残りわずか <b>x</b> 空席なし
              </div>
            </div>
            {isImmediateReservation && (
              <div className="input-group">
                <Label label={"お席をお選びください。"} required={false} />
                <List
                  control={control}
                  inputName={"tables"}
                  options={displayTableOnlineOptions}
                  valueName={"onlineBookingTableName"}
                  label={(item) => {
                    return (
                      <Button
                        type="primary"
                        onClick={() => handleShowDetailTableOnline(item)}
                      >
                        詳細
                      </Button>
                    );
                  }}
                  hasAllSelect={false}
                  hasMultipleSelect={false}
                  fullLineClick
                  showOverlay={displayTableOnlineOptions.length === 0}
                  overLayContent={() => {
                    return (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          background: "#00000050",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="form-seat-button"
                          style={{
                            padding: "10px 20px",
                            background: "#ffffff",
                            border: "1px solid #000000",
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                        >
                          時間、人数、日付を選択すると予約可能な席が表示されます。
                        </button>
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </div>
        </Col>
        <Col span={24} style={{ marginTop: isImmediateReservation ? 25 : 0 }}>
          <div className="input-group">
            <Label label={"メニュー"} required={menuRequired} />
            <div className="input-element">
              <List
                control={control}
                inputName={"menus"}
                options={menus}
                valueName={"netReservationName"}
                fullLineClick
                label={(item) => {
                  if (item.price > 0) {
                    if (item.tax) {
                      return (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{ marginRight: 20, whiteSpace: "nowrap" }}
                          >
                            {convertPrice(item.price) + "円(税別)"}
                          </span>
                          <Button
                            type="primary"
                            onClick={() => handleShowDetailMenu(item)}
                          >
                            詳細
                          </Button>
                        </span>
                      );
                    } else {
                      return (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{ marginRight: 20, whiteSpace: "nowrap" }}
                          >
                            {convertPrice(item.price) + "円(税込)"}
                          </span>
                          <Button
                            type="primary"
                            onClick={() => handleShowDetailMenu(item)}
                          >
                            詳細
                          </Button>
                        </span>
                      );
                    }
                  } else {
                    return (
                      <Button
                        type="primary"
                        onClick={() => handleShowDetailMenu(item)}
                      >
                        詳細
                      </Button>
                    );
                  }
                }}
                hasAllSelect={false}
                hasMultipleSelect={multipleMenuSelect}
              />
            </div>
          </div>
          {isIphone && menus.length !== 0 && (
            <div className="input-element">
              <img src={scrolldown} />
              メニュー選択欄はスクロールできます
            </div>
          )}
        </Col>
      </Row>
      <Row className="form-section" style={{ marginTop: 40 }}>
        <Col span={24}>
          <SectionHeader label={"予約者情報"} />
        </Col>

        <Col span={24}>
          <div className="input-group">
            <Label label={"お名前（カタカナまたはひらがな)"} />
            <div
              className="input-elements-inline reservation-input-name"
              style={{ flexWrap: "wrap" }}
            >
              <div className="input-element" style={{ marginRight: 25 }}>
                <Input
                  control={control}
                  inputName="lastName"
                  inputProps={{ placeholder: "セイ" }}
                />
              </div>
              <div className="input-element">
                <Input
                  control={control}
                  inputName="firstName"
                  inputProps={{ placeholder: "メイ" }}
                />
              </div>
            </div>
            {!fullnameRequired && (
              <div className="input-footer">※苗字のみでも可</div>
            )}
          </div>
        </Col>
        <Col span={24} style={{ marginTop: 25 }}>
          <div className="input-group">
            <Label label={"お電話番号"} />
            <div className="input-element input-element-phone">
              <PhoneInput
                control={control}
                inputName="phoneNumber"
                errors={errors.phoneNumber}
                suggestions={[]}
                handleAutoCompleteSelection={() => {}}
              />
            </div>
          </div>
        </Col>
        <Col span={24} style={{ marginTop: 25 }}>
          <div className="input-group">
            <Label label={"メールアドレス"} required={emailRequired} />
            <div className="input-element">
              <Input
                control={control}
                inputName="email"
                validation={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "メールアドレスが不正です",
                  },
                }}
                errors={errors.email}
                inputProps={{ placeholder: "mail@example.com" }}
                className="ant-input-custom"
              />
            </div>
            <div className="input-footer">※予約確認メールが送信されます</div>
          </div>
        </Col>
        <Col span={24} style={{ marginTop: 25 }}>
          <div className="input-group">
            <Label label={"ご要望欄"} required={false} />
            <div className="input-element" style={{ marginRight: 25 }}>
              <TextArea
                control={control}
                inputName={"note"}
                defaultValue={store.netReservation.netReservationRequestNote}
                inputProps={{ rows: 6, placeholder: "補足を記入してください" }}
              />
            </div>
          </div>
        </Col>
        {dispCampaign && (
          <Col span={24} style={{ marginTop: 25 }}>
            <div className="input-group">
              <Label label={"キャンペーン等のご案内"} required={false} />
              <div className="input-element" style={{ marginRight: 25 }}>
                <CheckboxSingle
                  control={control}
                  inputName="allowGroupMessage"
                  label={
                    "予約した店舗、系列店からキャンペーン等の情報を受け取る"
                  }
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row className="form-section" style={{ marginTop: 40 }}>
        <Title
          level={5}
          style={{ width: "100%", textAlign: "center", paddingBottom: 20 }}
        >
          個人情報の取り扱いについて
        </Title>
        <Text>
          本予約システムでご入力された情報その他お客様が本予約システムを利用することで取得される情報は、本店（予約対象の店舗）及び当社が取得し、当社は当社の定める下記「個人情報について」の規定に従い、お客様の個人情報を取り扱います。
          <br />
          ご予約の内容のご確認、下記「個人情報について」にご同意の上、「確定」ボタンを押して、予約を確定させてください。
        </Text>
        <Text
          style={{ width: "100%", textAlign: "center", paddingTop: "30px" }}
        >
          個人情報の取り扱い詳細については
          <a
            className="net-link"
            href="https://rejichoice.jp/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            プライバシーポリシー
          </a>
          をご覧ください
        </Text>
        <Text
          style={{
            width: "100%",
            textAlign: "center",
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <CheckboxSingle
            control={control}
            inputName="privacyAgreement"
            label={"「個人情報について」同意する"}
          />
        </Text>

        <Col span={24}>
          <Row justify="center" style={{ marginTop: 20 }}>
            <button
              type="submit"
              className="button button-primary button-primary-2"
            >
              確認
            </button>
          </Row>
        </Col>
      </Row>
      {tableOnlineDetail.showDialog && (
        <NetReservationTableDetailsModal
          isModalVisible={tableOnlineDetail.showDialog}
          tableOnlineData={tableOnlineDetail.data}
          handleCancel={handleCancel}
        />
      )}
      {menuDetail.showDialog && (
        <NetReservationMenuDetailModal
          isModalVisible={menuDetail.showDialog}
          menuData={menuDetail.data}
          handleCancel={handleMenuCancel}
        />
      )}
    </div>
  );
}

NetReservationForm.propTypes = {
  control: PropTypes.any,
  openHours: PropTypes.array,
  menus: PropTypes.array,
  setEndTime: PropTypes.func,
  getAvailableTable: PropTypes.func,
  availableTables: PropTypes.any,
  availableTablesOnline: PropTypes.any,
  errors: PropTypes.any,
  store: PropTypes.any,
  publicHolidays: PropTypes.array,
  multipleMenuSelect: PropTypes.any,
  emailRequired: PropTypes.any,
  menuRequired: PropTypes.any,
  fullnameRequired: PropTypes.any,
  dispCampaign: PropTypes.any,
  dispChildNum: PropTypes.any,
};

export default NetReservationForm;
