import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Radio, Space } from "antd";

export default function RadioButton({
  control,
  inputName,
  defaultValue,
  validation = {},
  buttons,
  classes = "radio-button-container",
  isFlat = true,
}) {
  let className = classes;
  if (!isFlat) {
    className = "radio-button-pointer";
  }
  return (
    <Controller
      control={control}
      name={inputName}
      rules={validation}
      defaultValue={defaultValue}
      render={({ onChange, value, name }) => (
        <Radio.Group
          className={className}
          defaultValue={defaultValue}
          onChange={(v) => onChange(v.target.value)}
          value={value}
          name={name}
        >
          {isFlat ? (
            buttons.map((button, index) => (
              <Radio.Button
                value={button.value}
                className="button-radio button-radio-default"
                key={index}
              >
                {button.label}
              </Radio.Button>
            ))
          ) : (
            <Space direction="vertical">
              {buttons.map((button, index) => (
                <Radio value={button.value} key={index}>
                  {button.label}
                </Radio>
              ))}
            </Space>
          )}
        </Radio.Group>
      )}
    />
  );
}

RadioButton.propTypes = {
  control: PropTypes.any,
  validation: PropTypes.any,
  inputName: PropTypes.string,
  inputProps: PropTypes.object,
  buttons: PropTypes.array,
  defaultValue: PropTypes.string,
  classes: PropTypes.string,
  isFlat: PropTypes.bool,
};
