import React from "react";
import PropTypes from "prop-types";
import { STROKE } from "constant";

export default function BellIcon({ width, height, customStyles }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyles}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0007 2.3335C9.43145 2.3335 7.92653 2.95686 6.81694 4.06645C5.70735 5.17604 5.08398 6.68097 5.08398 8.25016V12.8335C5.08398 13.673 4.75919 14.479 4.17919 15.0835H17.8221C17.2421 14.479 16.9173 13.673 16.9173 12.8335V8.25016C16.9173 6.68097 16.294 5.17604 15.1844 4.06645C14.0748 2.95686 12.5698 2.3335 11.0007 2.3335ZM20.1673 15.0835C19.5706 15.0835 18.9983 14.8464 18.5763 14.4245C18.1544 14.0025 17.9173 13.4302 17.9173 12.8335V8.25016C17.9173 6.41575 17.1886 4.65647 15.8915 3.35934C14.5943 2.06221 12.8351 1.3335 11.0007 1.3335C9.16624 1.3335 7.40696 2.06221 6.10983 3.35934C4.8127 4.65647 4.08398 6.41575 4.08398 8.25016V12.8335C4.08398 13.4302 3.84693 14.0025 3.42497 14.4245C3.00302 14.8464 2.43072 15.0835 1.83398 15.0835V16.0835H20.1673V15.0835ZM9.16393 18.8177C9.4028 18.6791 9.70876 18.7604 9.84732 18.9993C9.96453 19.2013 10.1328 19.369 10.3352 19.4856C10.5376 19.6022 10.7671 19.6636 11.0007 19.6636C11.2342 19.6636 11.4637 19.6022 11.6661 19.4856C11.8685 19.369 12.0368 19.2013 12.154 18.9993C12.2925 18.7604 12.5985 18.6791 12.8374 18.8177C13.0762 18.9562 13.1575 19.2622 13.019 19.501C12.8139 19.8546 12.5195 20.1481 12.1652 20.3522C11.811 20.5562 11.4094 20.6636 11.0007 20.6636C10.5919 20.6636 10.1903 20.5562 9.83605 20.3522C9.48183 20.1481 9.18743 19.8546 8.98232 19.501C8.84376 19.2622 8.92507 18.9562 9.16393 18.8177Z"
        fill={STROKE}
      />
    </svg>
  );
}

BellIcon.defaultProps = {
  width: "18",
  height: "18",
  customStyles: {},
};

BellIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  customStyles: PropTypes.object,
};
