import React from "react";
import PropTypes from "prop-types";

export default function OpenIcon({ stroke, className }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.5 0.933105C8.5 0.656963 8.72386 0.433105 9 0.433105H13C13.2761 0.433105 13.5 0.656963 13.5 0.933105V4.93311C13.5 5.20925 13.2761 5.43311 13 5.43311C12.7239 5.43311 12.5 5.20925 12.5 4.93311V2.14021L6.02022 8.61999C5.82496 8.81525 5.50838 8.81525 5.31311 8.61999C5.11785 8.42473 5.11785 8.10815 5.31311 7.91289L11.7929 1.43311H9C8.72386 1.43311 8.5 1.20925 8.5 0.933105ZM2.33333 3.43311C1.87614 3.43311 1.5 3.80925 1.5 4.26644V11.5998C1.5 11.8208 1.5878 12.0327 1.74408 12.189C1.90036 12.3453 2.11232 12.4331 2.33333 12.4331H9.66667C9.88768 12.4331 10.0996 12.3453 10.2559 12.189C10.4122 12.0327 10.5 11.8208 10.5 11.5998V8.26644C10.5 7.9903 10.7239 7.76644 11 7.76644C11.2761 7.76644 11.5 7.9903 11.5 8.26644V11.5998C11.5 12.086 11.3068 12.5523 10.963 12.8961C10.6192 13.24 10.1529 13.4331 9.66667 13.4331H2.33333C1.8471 13.4331 1.38079 13.24 1.03697 12.8961C0.693154 12.5523 0.5 12.086 0.5 11.5998V4.26644C0.5 3.25696 1.32386 2.43311 2.33333 2.43311H5.66667C5.94281 2.43311 6.16667 2.65696 6.16667 2.93311C6.16667 3.20925 5.94281 3.43311 5.66667 3.43311H2.33333Z"
        fill={stroke}
      />
    </svg>

    // <svg width="14" height="14" viewBox="0 0 14 14">
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M8.5 0.933105C8.5 0.656963 8.72386 0.433105 9 0.433105H13C13.2761 0.433105 13.5 0.656963 13.5 0.933105V4.93311C13.5 5.20925 13.2761 5.43311 13 5.43311C12.7239 5.43311 12.5 5.20925 12.5 4.93311V2.14021L6.02022 8.61999C5.82496 8.81525 5.50838 8.81525 5.31311 8.61999C5.11785 8.42473 5.11785 8.10815 5.31311 7.91289L11.7929 1.43311H9C8.72386 1.43311 8.5 1.20925 8.5 0.933105ZM2.33333 3.43311C1.87614 3.43311 1.5 3.80925 1.5 4.26644V11.5998C1.5 11.8208 1.5878 12.0327 1.74408 12.189C1.90036 12.3453 2.11232 12.4331 2.33333 12.4331H9.66667C9.88768 12.4331 10.0996 12.3453 10.2559 12.189C10.4122 12.0327 10.5 11.8208 10.5 11.5998V8.26644C10.5 7.9903 10.7239 7.76644 11 7.76644C11.2761 7.76644 11.5 7.9903 11.5 8.26644V11.5998C11.5 12.086 11.3068 12.5523 10.963 12.8961C10.6192 13.24 10.1529 13.4331 9.66667 13.4331H2.33333C1.8471 13.4331 1.38079 13.24 1.03697 12.8961C0.693154 12.5523 0.5 12.086 0.5 11.5998V4.26644C0.5 3.25696 1.32386 2.43311 2.33333 2.43311H5.66667C5.94281 2.43311 6.16667 2.65696 6.16667 2.93311C6.16667 3.20925 5.94281 3.43311 5.66667 3.43311H2.33333Z"
    //     fill={stroke}
    //   />
    // </svg>
  );
}

OpenIcon.propTypes = {
  stroke: PropTypes.string,
  className: PropTypes.string,
};
